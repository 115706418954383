const fontSizes = [
  ['desktop', 'h1', 48], // alias: desktop-h1
  ['desktop', 'h2', 24], // alias: desktop-h2
  ['desktop', 'h3', 20], // alias: desktop-h3
  ['desktop', 'h4', 18], // alias: desktop-h4
  ['desktop', 'h5', 20], // alias: desktop-h5
  ['desktop', 'body1', 16], // alias: desktop-body1
  ['desktop', 'body2', 14], // alias: desktop-body2
  ['desktop', 'legal', 14], // alias: desktop-legal

  ['tablet', 'h1', 36], // alias: tablet-h1
  ['tablet', 'h2', 24], // alias: tablet-h2
  ['tablet', 'h3', 24], // alias: tablet-h3
  ['tablet', 'h4', 18], // alias: tablet-h4
  ['tablet', 'h5', 18], // alias: tablet-h5
  ['tablet', 'body1', 16], // alias: tablet-body1
  ['tablet', 'body2', 16], // alias: tablet-body2
  ['tablet', 'legal', 14], // alias: tablet-legal

  ['mobile', 'h1', 30], // alias: mobile-h1
  ['mobile', 'h2', 21], // alias: mobile-h2
  ['mobile', 'h3', 20], // alias: mobile-h3
  ['mobile', 'h4', 16], // alias: mobile-h4
  ['mobile', 'h5', 16], // alias: mobile-h5
  ['mobile', 'body1', 15], // alias: mobile-body1
  ['mobile', 'body2', 14], // alias: mobile-body2
  ['mobile', 'legal', 12], // alias: mobile-legal
];

// Loading the fonts this way will duplicate the requests
// The `woff2` should be the first one to load after `local()`
// then followed by `woff`
// What this basically means is that, if the browser found it exists on locally
// Then it will skip loading the rest of the files. If the browser sees that the
// browser supports woff2, it will skip the rest (https://caniuse.com/woff2)
// However, in this approach, since we're loading it through JS, it adds extra
// script evaluation and it actually loads all fonts + it is delayed (Resulting to FOUT)

const imperativelyExtractFontSizeAlias = (themeFontSizes = []) => {
  let newFontSizes = themeFontSizes.map(([, , fontSize]) => fontSize);

  themeFontSizes.forEach((themeFontSize) => {
    const [platform, semantic, fontSize] = themeFontSize;

    // see alias
    const alias = [platform, semantic].join('-');

    newFontSizes = Object.defineProperty(newFontSizes, alias, {
      value: fontSize,
    });
  });
  return newFontSizes;
};

const breakpoints = [
  '640px', // 40em
  '832px', // 52em
  '1920px', // 120em
  '2560px', // 160em
];

const colors = {
  mintCream: '#F8FBF9',
  slateBlue: '#7264D8',
  nero: '#1F1F1F',
  tropicalRainForest: '#007B5C',
  gray: '#767676',
  darkRed: '#910600',

  wagGreen: '#00BF8F',
  wagGold: '#FAAB43',
  wagGray: '#D8D8D9',

  white: '#fff',

  // TODO currently just here to support global styles,
  // to be removed when Typography component is overhauled
  medGray: '#1F1F1F',
};

export const wagwebTheme = {
  breakpoints,
  colors,
  fontSizes: imperativelyExtractFontSizeAlias(fontSizes),
};
