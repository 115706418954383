'use client';

import React, { useCallback, useState, ReactNode, SyntheticEvent } from 'react';
import { Box } from '../../wag-react/core-components/box';
import { AccordionItemContext } from './AccordionItemContext';
import { AccordionPanelWrapper } from './components/AccordionPanelWrapper';
type Props = {
  isExpanded?: boolean;
  children: ReactNode;
  onChange?: (
  // ! FIXME: `event` proper type definition
  event: SyntheticEvent, isExpanded: boolean) => void;
};
export const AccordionItem = ({
  isExpanded: isExpandedProp,
  children,
  onChange,
  ...rest
}: Props) => {
  const [expandedState, setExpandedState] = useState(false);
  const isControlled = isExpandedProp !== undefined;
  const isExpanded = isControlled ? Boolean(isExpandedProp) : expandedState;
  const handleChange = useCallback((event: SyntheticEvent) => {
    if (!isControlled) {
      setExpandedState(!isExpanded);
    }
    if (onChange) {
      onChange(event, !isExpanded);
    }
  }, [isControlled, isExpanded, onChange]);
  const [AccordionHeader, AccordionPanel] = React.Children.toArray(children);
  return <Box borderBottom="1px solid" borderColor="wagGray" hover {...rest} data-sentry-element="Box" data-sentry-component="AccordionItem" data-sentry-source-file="index.tsx">
      <AccordionItemContext.Provider value={{
      isExpanded,
      toggle: handleChange
    }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        {AccordionHeader}
      </AccordionItemContext.Provider>
      <AccordionPanelWrapper isExpanded={isExpanded} data-sentry-element="AccordionPanelWrapper" data-sentry-source-file="index.tsx">
        {AccordionPanel}
      </AccordionPanelWrapper>
    </Box>;
};