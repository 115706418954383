import React, { FunctionComponent, ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { wagwebTheme } from '@/theme/wagwebTheme';
import { StyledSystemProps } from '../../types';
import { BoxProps } from '../../wag-react/next-components/box';
import { Link as WagLink } from '../../wag-react/core-components/link';
export type LinkProps = Pick<JSX.IntrinsicElements['a'], 'title'> & {
  children: ReactNode;
  href: string;
  target?: string;
  style?: CSSProperties;
  rel?: string;
} & StyledSystemProps;
export const HoverLink = ({
  children,
  href,
  target,
  ...rest
}: LinkProps) => <Link color={wagwebTheme.colors.slateBlue} fontWeight="700" href={href} target={target} {...rest} data-sentry-element="Link" data-sentry-component="HoverLink" data-sentry-source-file="HoverLink.tsx">
    {children}
  </Link>;
const Link = styled(WagLink)`
  &:hover {
    opacity: 0.7;
  }
` as FunctionComponent<React.PropsWithChildren<BoxProps>>;