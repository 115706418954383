import React, { useContext, ReactNode } from 'react';
import { AccordionItemContext } from '../AccordionItem/AccordionItemContext';
import { Flex } from '../Flex';
import minusWagGreenIcon from '../../assets/icons/minus-wag-green.svg';
import { Box, BoxProps } from '../../wag-react/next-components/box';
import { Icon } from '../../wag-react/components/icon';
import { Image } from '../../wag-react/core-components/image';
type Props = {
  expandIcon?: ReactNode;
  contractIcon?: ReactNode;
  children: ReactNode;
} & BoxProps;
export type AccordionHeaderProps = Props;
export const AccordionHeader = ({
  expandIcon = <Icon name="plusGreen" width={18} height={18} alt="plus-wag-green-icon" hover />,
  contractIcon = <Image src={minusWagGreenIcon} width={18} height={18} alt="minus-wag-green-icon" hover />,
  children,
  ...rest
}: Props) => {
  const {
    isExpanded,
    toggle
  } = useContext(AccordionItemContext);
  return <Flex alignItems="center" cursor="pointer" justifyContent="space-between" onClick={toggle} pr={['8px', 0]} {...rest} data-sentry-element="Flex" data-sentry-component="AccordionHeader" data-sentry-source-file="index.tsx">
      <Box cursor="inherit" data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {children}
      </Box>
      <Box cursor="inherit" data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {isExpanded ? contractIcon : expandIcon}
      </Box>
    </Flex>;
};