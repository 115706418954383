import React, { ReactNode } from 'react';
import { Typography } from '../Typography';
import { Markdown } from '../Markdown';
import { ReworkedMarkdown } from '../Markdown/Reworked';
type Props = {
  item: Record<string, string | string[] | ReactNode>;
  useMarkdown?: boolean;
  useReworkedMarkdown?: boolean;
};
export const AccordionBody = ({
  item,
  useMarkdown,
  useReworkedMarkdown
}: Props) => {
  if (Array.isArray(item.body)) {
    return <>
        {item.body.map(text => <Typography key={text}>
            {text}
          </Typography>)}
      </>;
  }
  if (useReworkedMarkdown) {
    return <ReworkedMarkdown version="react-markdown">
        {item.body as ReactNode & string}
      </ReworkedMarkdown>;
  }
  if (useMarkdown) {
    return <Markdown>
        {item.body}
      </Markdown>;
  }
  return <Typography data-sentry-element="Typography" data-sentry-component="AccordionBody" data-sentry-source-file="index.tsx">
      {item.body}
    </Typography>;
};