/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Markdown, { Options } from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import omit from 'ramda/src/omit';
import { HoverLink } from '@/components/HoverLink/HoverLink';
import { Typography } from '../../../../Typography';
import { LazyImage } from '../../../../../wag-react/components/lazyload/LazyImage';
import { UL } from '../../../../../wag-react/core-components/ul';
import { LI } from '../../../../../wag-react/core-components/li';
import { Strong } from '../../../../../wag-react/core-components/strong';
export const defaultComponents: Options['components'] = {
  h1: ({
    node,
    ...props
  }) => <Typography variant="h1" {...props} />,
  h2: ({
    node,
    ...props
  }) => <Typography variant="h2" {...props} />,
  h3: ({
    node,
    ...props
  }) => <Typography variant="h3" {...props} />,
  h4: ({
    node,
    ...props
  }) => <Typography variant="h4" {...props} />,
  h5: ({
    node,
    ...props
  }) => <Typography variant="h5" {...props} />,
  p: ({
    node,
    ...props
  }) => <Typography variant="body1" lineHeight="28px" my="12px" {...props} />,
  span: ({
    node,
    ...props
  }) => {
    const {
      style
    } = props;
    /**
     * Omit specific props to maintain consistency display
     *
     * TODO - should we do this on other typography elements too?
     * TODO - should we prevent CMS from using `span`? How?
     */
    const restProps = omit(['fontSize', 'fontFamily', 'color'], props);
    const restStyle = omit(['fontSize', 'fontFamily', 'color'], style);
    return <Typography variant="body2" lineHeight="22px" {...restProps as typeof props} style={restStyle} />;
  },
  ul: ({
    node,
    ...props
  }) => <UL display="grid" my="12px" pl="20px" fontSize={['mobile-body1', 'tablet-body1', 'desktop-body1']} {...props} />,
  li: ({
    node,
    ...props
  }) => <LI {...props} lineHeight="28px" />,
  a: ({
    node,
    ...props
  }) => <HoverLink
  // The `[key: string]: unknown;` isn't something this component like
  href={props.href} {...props} target="_blank" style={{
    wordWrap: 'break-word',
    textDecoration: 'underline'
  }} />,
  b: ({
    node,
    ...props
  }) => <Strong {...props} />,
  img: ({
    node,
    ...props
  }) => {
    // The `[key: string]: unknown;` isn't something this component like
    const {
      src,
      width,
      height
    } = props;
    return <LazyImage src={src} {...props}
    // On mobile and tablet, use a 4:3 aspect ratio
    // on large screens, use the provided height/width
    width={['336px', '490px', width]} height={['252px', '368px', height]} />;
  }
};
export type ReworkedReactMarkdownProps = Options;
export const ReworkedReactMarkdown = ({
  children,
  components = defaultComponents,
  ...rest
}: ReworkedReactMarkdownProps) => <Markdown rehypePlugins={[rehypeRaw]} components={components} {...rest} data-sentry-element="Markdown" data-sentry-component="ReworkedReactMarkdown" data-sentry-source-file="index.tsx">
    {children}
  </Markdown>;