import React, { useRef } from 'react';
import { Box, BoxProps } from '../../../wag-react/next-components/box';
type Props = {
  isExpanded: boolean;
} & BoxProps;
export const AccordionPanelWrapper = ({
  isExpanded,
  ...rest
}: Props) => {
  const ref = useRef<HTMLDivElement>();
  const scrollHeight = ref.current && ref.current.scrollHeight || 0;
  return <Box ref={ref} height={isExpanded ? scrollHeight : 0} overflowY={isExpanded ? 'hidden' : 'scroll'} style={{
    visibility: isExpanded ? 'visible' : 'hidden'
  }} transition="height 0.3s ease-out" {...rest} data-sentry-element="Box" data-sentry-component="AccordionPanelWrapper" data-sentry-source-file="AccordionPanelWrapper.tsx" />;
};