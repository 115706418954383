import React, { ReactNode } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import JSXMarkdown, { MarkdownToJSX } from 'markdown-to-jsx';
import { Link } from '../../Link';
import { Typography } from '../../Typography';
import { wagwebTheme } from '../../../theme/wagwebTheme';
import { ReworkedReactMarkdown, ReworkedReactMarkdownProps } from '../components/ReactMarkdown/Reworked';
import { LazyYoutube } from '../../../wag-react/components/lazyload/LazyYoutube';
import { OL } from '../../../wag-react/core-components/ol';
import { UL } from '../../../wag-react/core-components/ul';
import { LI } from '../../../wag-react/core-components/li';
type Props = {
  children: ReactNode;
} & ({
  options?: MarkdownToJSX.Options;
  /**
   * TODO - Remove support of `markdown-to-jsx` and
   * migrate other pages to use `react-markdown` version
   */
  /**
   * @deprecated - For future markdown implementations, please use `react-markdown`.
   * ```
   * <Markdown version="react-markdown" />
   * ```
   *
   */
  version?: 'markdown-to-jsx';
} | (ReworkedReactMarkdownProps & {
  options?: never;
  version?: 'react-markdown';
}));

// This will allow users to combine the default and their new options if needed
export const defaultOptions: Readonly<MarkdownToJSX.Options> = {
  namedCodesToUnicode: {
    ordm: '\u00BA'
  },
  overrides: {
    h1: {
      component: Typography,
      props: {
        variant: 'h1'
      }
    },
    h2: {
      component: Typography,
      props: {
        variant: 'h2'
      }
    },
    h3: {
      component: Typography,
      props: {
        variant: 'h3',
        mt: 4
      }
    },
    h4: {
      component: Typography,
      props: {
        variant: 'h4',
        mt: 4
      }
    },
    h5: {
      component: Typography,
      props: {
        variant: 'h5',
        mt: 4
      }
    },
    p: {
      component: Typography,
      props: {
        variant: 'body1'
      }
    },
    // TODO - Currently API is returning the Vet Answer as span; It should be <p>.
    // we might need to change this later
    span: {
      component: Typography,
      props: {
        variant: 'body1',
        style: {
          wordWrap: 'break-word'
        }
      }
    },
    ol: {
      component: OL,
      props: {
        // TODO - An OL should not be using `disc` semantically
        listStyle: 'disc'
      }
    },
    ul: {
      component: UL,
      props: {
        display: 'grid',
        gridGap: '8px'
      }
    },
    li: {
      component: LI,
      props: {
        // New colour: Darker gray
        color: wagwebTheme.colors.nero
      }
    },
    a: {
      component: Link,
      props: {
        target: '_blank',
        color: 'wagGreen',
        style: {
          wordWrap: 'break-word'
        }
      }
    },
    b: {
      props: {
        style: {
          // New colour: Darker gray
          color: wagwebTheme.colors.nero
        }
      }
    },
    iframe: {
      component: LazyYoutube,
      props: {
        useIframe: true,
        // Change from original - Fixed padding and aligned to left
        mt: '50px',
        mr: 'auto',
        iframeProps: {
          height: 267,
          // YouTube videos are typically 16:9 aspect ratio
          width: ['100%', '100%', '475px'],
          // YouTube videos are typically 16:9 aspect ratio
          frameBorder: '0',
          allowFullScreen: true,
          title: 'Wag! Video'
        }
      }
    }
  }
};

// chidlren of <Markdown /> is only accepting string
/**
 * TODO - Remove support of `markdown-to-jsx` and
 * migrate other pages to use `react-markdown` version
 */

export const ReworkedMarkdown = ({
  children,
  options = defaultOptions,
  version = 'markdown-to-jsx',
  ...rest
}: Props) => <>
    <ThemeProvider theme={wagwebTheme} data-sentry-element="ThemeProvider" data-sentry-source-file="index.tsx">
      <GlobalStyle data-sentry-element="GlobalStyle" data-sentry-source-file="index.tsx" />
      {version === 'react-markdown' && <ReworkedReactMarkdown className="react-markdown" {...rest}>
          {children as string}
        </ReworkedReactMarkdown>}
      {version === 'markdown-to-jsx' && <JSXMarkdown options={options} {...rest}>
          {children as string}
        </JSXMarkdown>}
    </ThemeProvider>

  </>;

/* For some reason the overriding the `p` element with `<Typography variant="body1" />`
  doesn't work consistently. This is the workaround we have to make sure we are rendering
  the right color on the element */
const GlobalStyle = createGlobalStyle`
  p {
    color: ${wagwebTheme.colors.nero};
  }
  /* This is an extra layer styling measure whereas there will be no content
    that will have different color unless explicitly provided
    This will allow us to determine the words/sections/items we have to adjust on the CMS
    Ideally, all text should be wrapped in a <p> element

    These styles are the basic ones but let's try our best to NOT add more in here
    because most of the time the fix can be done on the CMS (i.e ensuring texts are wrapped in a <p> element)
  */
  .react-markdown {
    color: ${wagwebTheme.colors.nero};
    font-size: 16px;
    font-family: Muli, Helvetica, Arial, sans-serif;
  }
`;
export type MarkdownProps = Props;