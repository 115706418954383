import React, { ReactNode } from 'react';
import { Box, BoxProps } from '../../wag-react/next-components/box';
import { AccordionBody } from '../AccordionBody';
import { AccordionHeader, AccordionHeaderProps } from '../AccordionHeader';
import { AccordionItem } from '../AccordionItem';
import { AccordionPanel, AccordionPanelProps } from '../AccordionPanel';
import { Typography } from '../Typography';
type Props = {
  items: Array<Record<string, string | string[] | ReactNode>>;
  useMarkdown?: boolean;
  useReworkedMarkdown?: boolean;
  accordionTitleProps?: Omit<BoxProps, 'children'>;
  panelProps?: AccordionPanelProps;
  headerProps?: Omit<AccordionHeaderProps, 'children'>;
} & BoxProps;
export type AccordionProps = Props;
export const Accordion = ({
  items,
  useMarkdown,
  accordionTitleProps,
  panelProps,
  headerProps,
  useReworkedMarkdown,
  ...rest
}: Props) => <Box {...rest} data-sentry-element="Box" data-sentry-component="Accordion" data-sentry-source-file="index.tsx">
    {items.map(item => <AccordionItem key={item.header.toString()}>
        <AccordionHeader {...headerProps}>
          <Typography hover fontWeight="bold" {...accordionTitleProps}>
            {item.header}
          </Typography>
        </AccordionHeader>
        <AccordionPanel {...panelProps}>
          <AccordionBody item={item} useMarkdown={useMarkdown} useReworkedMarkdown={useReworkedMarkdown} />
        </AccordionPanel>
      </AccordionItem>)}
  </Box>;